.reviewContainer {
  background-color: #333333;
}

.reviewContainer .reviewTitle {
  display: flex;
  padding-top: 75px;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
}

.comingsoon {
  text-align: center;
  margin-top: 150px;
  font-size: 50px;
}

.services-title {
  margin: 0;
}

.services-description {
  margin-top: 35px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  font-size: 20px;
}

@media (min-width: 768px) {
  .services-description {
    margin-left: 250px;
    margin-right: 250px;
  }
}

.underline-review {
  display: block;
  width: 850px;
  height: 4px;
  background-color: #ee2737;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.reviewWrapper {
  height: auto;
  width: auto;
  display: flex;
  margin-top: 50px;
  margin-bottom: 100px;
}

.reviewWrapper .review {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.reviewWrapper .review .title {
  font-size: 28px;
  display: flex;
  justify-content: center;
}

.reviewWrapper .review .reviewFotoWrapper {
  display: flex;
  margin-top: 30px;
}

.reviewWrapper .review .reviewFotoWrapper .fotoLeft,
.reviewWrapper .review .reviewFotoWrapper .fotoRight {
  display: flex;
  height: 300px;
  width: 300px;
  flex-direction: column;
  align-items: center;
}

.reviewWrapper .review .reviewFotoWrapper .fotoLeft {
  align-items: flex-end;
}

.reviewWrapper .review .reviewFotoWrapper .fotoRight {
  align-items: flex-start;
}

.reviewWrapper .review .reviewFotoWrapper .reviewFoto {
  width: 95%;
  transition: transform 0.2s; /* Animation */
}

.reviewFoto:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  cursor: pointer;
}

.reviewWrapper .review .reviewFotoWrapper .timeText {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  justify-content: center;
  display: flex;
}

.reviewWrapper .review .reviewFotoWrapper .timeText.before {
  color: white;
}

.reviewWrapper .review .reviewFotoWrapper .timeText.after {
  color: #ad0909;
}

.reviewWrapper .review .description {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.reviewLine {
  background: #ad0909;
  color: #ad0909;
  border-color: #ad0909;
  margin-block-start: 0em;
  margin-block-end: 0em;
  width: 8px;
}

.reviewContainer .moreReviews {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 50px;
}

@media screen and (max-width: 1104px) {
  .reviewWrapper {
    flex-direction: column;
    margin: 10px;
  }
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 9999;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
